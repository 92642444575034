import React from 'react';
import HeroVideo from '../../assets/hero/Sequence05_compressed.mp4';
import { DesignFrame } from '../../shared-components/design-frame/design-frame';
import HeroPoster from '../../assets/hero/Sequence05_fallback.png';

export const HeroSection = () => {
 
    return(
        <section className='page-section hero-section full-screen-section'>
            <div className='hero-content'>
                <DesignFrame 
                    title='Prove your ML' 
                    buttonText={"Let's work together >"}
                    onButtonClick={() => window.open('mailto:team@zeroml.io?subject=ZeroML')}
                    text={`ML works as a black box. With zkml it still works like a black box, but this time this box cannot be changed without outsiders knowing about it. Revolutionise the way data is analysed, processed and stored with privacy as a first principle.`}/>
            </div>
            <video id="heroVideo" className='full-screen-video-box' autoPlay muted loop playsInline poster={HeroPoster}>
                <source src={HeroVideo} type="video/mp4"/>
            </video>
        </section>
    );

}