import React, { useState } from 'react';
import TraditionalImage from '../../assets/traditional-vs-zk/Traditional/StaticImage/Traditional.svg';
import ZKImage from '../../assets/traditional-vs-zk/ZK/StaticImage/ZK.svg';
// import HoverableImage1 from '../../assets/traditional-vs-zk/Traditional/HoverableImage/'
import User from '../../assets/traditional-vs-zk/User.svg';
import Head from '../../assets/traditional-vs-zk/Head.svg';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/userlottie.json';

export const TraditionalVSZKSection = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

      const [traditionalHover, setTraditionalHover] = useState(false);
      const [zkHover, setZKHover] = useState(false);

    return(
        <section id="traditional" className='page-section traditional-vs-zk-section'>
            {/* <div className='traditional-vs-zk-title-wrap'> */}
                <div className='traditional-vs-zk-title-box tzv-section-item left'>
                    <h2 className='traditional-vs-zk-title uppercase'>Traditional <br/> Machine Learning</h2>
                </div>
                <div className='traditional-vs-zk-title-box tzv-section-item  right'>
                    <h2 className='traditional-vs-zk-title uppercase'>Machine Learning <br/> with Zero Knowlegde</h2>
                </div>
            {/* </div> */}
            {/* <div className='traditional-vs-zk-image-wrap'> */}
                <div className='traditional-vs-zk-image tzv-section-item left'>
                    <div className='traditional-image-wrap'>
                        <img src={TraditionalImage} alt='traditional'/>
                    </div>
                    <div className='traditional-user-wrap'>
                        <span className='question-marks'>???</span>
                        <div className='user-animation-wrap imageVisible'>

                            <Lottie 
                                options={defaultOptions}
                                height={100}
                                width={100}
                                isStopped={traditionalHover}
                            />
                            <div 
                                className='traditional-user-icon-wrap user-icon-wrap tooltip' 
                                onMouseOver={() => setTraditionalHover(true)} 
                                onMouseOut={() => setTraditionalHover(false)} >
                                <div className="top">
                                    <p>A regular user doesn't really know what happens with their data in traditional ML.</p>
                                    <i></i>
                                </div>
                                <img src={User} alt='user' className='imageVisible'/>
                                <img src={Head} alt='head' className='outlineVisible'/>
                            </div>
                        </div>
                        <span className='capitalize bold'>Regular user</span>
                        
                    </div>
                </div>
                <div className='traditional-vs-zk-image tzv-section-item right'>
                    <div className='zk-image-wrap'>
                            <img src={ZKImage} alt='zk'/>
                    </div>
                    <div className='zk-user-wrap'>
                        <div className='user-animation-wrap'>
                           <Lottie 
                                options={defaultOptions}
                                height={100}
                                width={100}
                                isStopped={zkHover}
                            />
                        

                            <div className='zk-user-icon-wrap user-icon-wrap tooltip' 
                             onMouseOver={() => setZKHover(true)} 
                             onMouseOut={() => setZKHover(false)} >
                                <div className="top">
                                    <p>A regular user can transparently verify data set ML was trained upon, verify that it runs on a uniform structure or that the same ML runs for all the users.</p>
                                    <i></i>
                                </div>
                                <img src={User} alt='user' className='imageVisible'/>
                                <img src={Head} alt='head' className='outlineVisible'/>
                            </div>
                        </div>
                        <span className='capitalize bold'>Regular user</span>
                        
                    </div>
                </div>
            {/* </div> */}
            {/* <div className='traditional-vs-zk-text-wrap'> */}
                <div className='traditional-vs-zk-text-box-wrap  tzv-section-item left'>
                    <div className='traditional-vs-zk-text-box'>
                        <p>Traditional machine learning is based on a premise that the machine learning model that is used really exists, that it is used always, and that the data it is learning from is really the data that creator is claiming it is. So, this approach poses a significant risk to privacy and security, and also is prone to manipulation by ml not running the way it claimed it should.</p>
                    </div>
                </div>
                <div className='traditional-vs-zk-text-box-wrap tzv-section-item right'>
                    <div className='traditional-vs-zk-text-box'>
                        <p>ZKML uses cryptography to enable machine learning analysis on encrypted data. Data owner can encrypt their data before sharing it with the machine learning model, ensuring that the original data remains private and confidential.</p> 
                        <span>ZKML commits creator to some of these things or all of them:  </span>
                            <ul>
                                <li>Training set is what is claimed to be</li>
                                <li>Same ml structure runs for all the users</li>
                                <li>Same ml runs for all the users</li>
                            </ul>
                      
                    </div>
                </div>
            {/* </div> */}
        </section>
    );

}