import React, { createContext, useState } from "react";

export const InterfaceContext = createContext();

const InterfaceContextProvider = ({ children }) => {
	const [responsiveMenuBarVisible, setResponsiveMenuBarVisible] = useState(false);

    const handleOpenResponsiveMenu = () => {
        setResponsiveMenuBarVisible(true);
      
    }

    const handleCloseResponsiveMenu = () => {
        setResponsiveMenuBarVisible(false);
       
    }

	return (
		<InterfaceContext.Provider value={{ 
			responsiveMenuBarVisible,
            setResponsiveMenuBarVisible,
            handleOpenResponsiveMenu,
            handleCloseResponsiveMenu
            }}>
			{ children }
		</InterfaceContext.Provider>
	)
}

export default InterfaceContextProvider;