import React from 'react';
import { DesignFrame } from '../../shared-components/design-frame/design-frame';
import ContactVideoBackground from '../../assets/contact-us/Sequence03_compressed.mp4';
import ContactPoster from '../../assets/contact-us/Sequence03_fallback.png';

export const ContactUsSection = () => {

    return(
        <section id='contactus' className='page-section contact-us-section full-screen-section'>
            <div className='contact-us-content'>
                <DesignFrame
                    title='Contact us!' 
                    buttonText={"Get in touch >"}
                    onButtonClick={() => window.open('mailto:team@zeroml.io?subject=ZeroML')}
                >
                    <span className='contact-us-section-text'>
                    If you’re a member of <a href='https://t.me/zkmlcommunity' target='_blank' rel="noreferrer" className='clickable'>The ML for Privacy</a> group, or similar we would love to get in touch. Also, don’t hesitate to reach us out if you have a ZKML use case idea that you would like to build upon.
                    </span>
                </DesignFrame>
            </div>
            <video id="contactVideoBackground" className='full-screen-section-video-box' autoPlay muted loop playsInline poster={ContactPoster}>
                <source src={ContactVideoBackground} type="video/mp4"/>
            </video>
        </section>
    );

}