import React from 'react';
import { DesignFrame } from '../../shared-components/design-frame/design-frame';
import GitHubIcon from '../../assets/library/git-logo/github.svg';
import LibraryVideo from '../../assets/library/Sequence02_compressed.mp4';
import LibraryPoster from '../../assets/library/Sequence02_fallback.png';

export const LibrarySection = () => {

    return(
        <section id='library' className='page-section library-section full-screen-section '>
            <div className='library-section-content'>
                <DesignFrame 
                    title='OUR SKProof LIBRARY' 
                    buttonText={"Check it out on GitHub"}
                    buttonIcon={GitHubIcon}
                    onButtonClick={() => window.open("https://github.com/0x3327/SKProof", "_blank") }
                >
                    <span className='library-section-text'>
                        We have built SKProof <a href='https://www.python.org/' target='_blank' rel="noreferrer" className='clickable'>Python</a> library that enables generation of execution proofs for machine learning models found in <a href='https://scikit-learn.org/' target='_blank'  rel="noreferrer" className='clickable'>scikit-learn</a> library.
                    </span>
                </DesignFrame>
            </div>
            <video id="heroVideo" className='full-screen-section-video-box' autoPlay muted loop playsInline poster={LibraryPoster}>
                <source src={LibraryVideo} type="video/mp4"/>
            </video>
        </section>
    );

}