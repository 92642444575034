
import './App.css';
import { HeroSection } from './sections/hero-section/hero-section';
import { LibrarySection } from './sections/library-section/library-section';
import { DemoSection } from './sections/demo-section/demo-section';
import { FooterSection } from './sections/footer-section/footer-section';
import { ContactUsSection } from './sections/contact-us-section/contact-us-section';
import { UseCasesSection } from './sections/use-cases-section/use-cases-section';
import { TraditionalVSZKSection } from './sections/traditional-vs-zk-section/traditional-vs-zk-section';
import { HeaderSection } from './sections/header-section/header-section';
import { InterfaceContext } from './context/interface-context';
import { useContext } from 'react';
import { ReactComponent as Close }  from './assets/hero/close.svg';

export const App = () => {
  const {responsiveMenuBarVisible, handleCloseResponsiveMenu} = useContext(InterfaceContext);

  return (
   
      <div className="App">
          {responsiveMenuBarVisible && 
          <div className='responsive-blur'>
            <div className='menu-responsive-bar'>
                <div className='close-responsive-menu-icon-wrap clickable'>
                    <Close onClick={() =>handleCloseResponsiveMenu()} />
                </div>
                <div className='menu-responsive-items-wrap'>
                    <ul>
                        <li><a href="#traditional" className='clickable' onClick={() => handleCloseResponsiveMenu()} >How it Works</a></li>
                        <li><a href="#usecases" className='capitalize clickable' onClick={() => handleCloseResponsiveMenu()} >Use Cases</a></li>
                        <li><a href="#library" className='capitalize clickable' onClick={() => handleCloseResponsiveMenu()} >Library</a></li>
                        <li><a href="#demo" className='capitalize clickable' onClick={() => handleCloseResponsiveMenu()} >Demo</a></li>
                        <li><a href="#contactus" className='uppercase accent-text clickable' onClick={() => handleCloseResponsiveMenu()} >Get in touch</a></li>
                    </ul>
                </div>
            </div>
          </div>}
          <HeaderSection/>
          <HeroSection/>
          <TraditionalVSZKSection/>
          <UseCasesSection/>
          <LibrarySection/>
          <DemoSection/>
          <ContactUsSection/>
          <FooterSection/>
      </div>
   
  );
}

export default App;
