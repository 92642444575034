import React from 'react';


export const FooterSection = () => {

    return(
        <footer className='page-section footer-section'>
            <span className='footer-text'><span className='bold'>ZeroML</span>  🤍  Made with love for privacy!</span>
        </footer>
    );

}