import React from 'react';


export const PropertyBox = ({icon, title, contentText}) => {
    
    return(
        <div className='property-box'>
            {icon && <img src={icon} alt='icon'/>}
            <div className='property-box-content'>
                <h2 className='property-box-title uppercase'>{title}</h2>
                <p className='property-box-content-text'>{contentText}</p>
            </div>
        </div>
    );
}