import React from 'react';
import { ColoredChip } from '../../shared-components/colored-chip/colored-chip';
import { PropertyBox } from '../../shared-components/property-box/property-box';
import SecureMLIcon from '../../assets/use-cases/secure_ml/secureml.svg';
import VerifiableMLIcon from '../../assets/use-cases/verifiable-ml/verifiableml.svg';
import PrivacyFocusedML from '../../assets/use-cases/privacy-focused-ml/privacyfocusedml.svg';


export const UseCasesSection = () => {

    return(
        <section id='usecases' className='page-section use-cases-section'>
            <div className='use-cases-section-cases'>
                <h1 className='uppercase section-title'>Use cases</h1>
                <p className='use-cases-description'>ZKML has several use cases where privacy and computational integrity are of a great value to an organization or individual.</p>
                <div className='chips-wrap'>
                    <ColoredChip text={"Healthcare"} color="#D4CBF9"/>
                    <ColoredChip text={"Transportation"} color="#BFF9B4"/>
                    <ColoredChip text={"Advertising"} color="#9C96F5"/>
                    <ColoredChip text={"Finance"} color="#ACE6FF"/>
                </div>
            </div>
            <div className='use-cases-section-properties'>
                <PropertyBox 
                    title={"Secure ML"} 
                    contentText={"Utilizing zk-SNARKs safeguards the authenticity of ML models, confirming that they remain unaltered by substitute models. This proves advantageous in scenarios where models are implemented in potentially unreliable settings, including edge computing devices or communal cloud systems."}
                    icon={SecureMLIcon}/>
                <PropertyBox 
                    title={"Verifiable ML"} 
                    contentText={"Leveraging zkp ensures a specific model was used for prediction, without revealing the model or any ML details like the data itself, bolstering trust in vital applications in various industries like banking or medicine."}
                    icon={VerifiableMLIcon}/>
                <PropertyBox 
                    title={"Privacy Focused ML"} 
                    contentText={"Zkp enables the training of machine learning models on confidential data while keeping it concealed from the model's developers and users. Models can be created for industries like healthcare or finance without risking the privacy of the people whose data is used."}
                    icon={PrivacyFocusedML}/>
            </div>
        </section>  
    );

}