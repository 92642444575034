import React from "react";

export const DesignFrame = ({children, title, text, buttonText, onButtonClick, buttonIcon}) => {

    return(
        <div className="design-frame-wrap">
            <div className="design-frame-outer">
                <div className="design-frame-inner">
                    <h2 className='design-frame-title uppercase'>{title}</h2>
                    <span className='design-frame-text'>{text || children}</span>
                    <button className="design-frame-button uppercase clickable" onClick={() => onButtonClick && onButtonClick()}>
                        {buttonIcon && <img src={buttonIcon} alt='git'/>} 
                        <span className='button-text'>{buttonText}</span>
                        </button>
                </div>
            </div>
        </div>
    )
}