import React from 'react';


export const DemoSection = () => {

    return(
        <section id="demo" className='page-section demo-section'>
            <h1 className='demo-section-title uppercase'>Demo</h1>
        </section>
    );

}