import React, { useContext } from 'react';
import logo from '../../assets/logo/Logo.svg';
import { ReactComponent as Menu } from '../../assets/hero/menu.svg';
import { InterfaceContext } from '../../context/interface-context';


export const HeaderSection = () => {
    const {responsiveMenuBarVisible, handleOpenResponsiveMenu} = useContext(InterfaceContext);

    return (
        <header id="header" className={`page-section header-section content-wrap`} >
            <div className={`logo-wrap`}>
                <img className={`logo`} src={logo} alt='Zero ML' />
            </div>
            <div className='menu-wrap'>
                <ul>
                    <li><a href="#traditional" className='clickable'>How it Works</a></li>
                    <li><a href="#usecases" className='capitalize clickable'>Use Cases</a></li>
                    <li><a href="#library" className='capitalize clickable'>Library</a></li>
                    <li><a href="#demo" className='capitalize clickable'>Demo</a></li>
                    <li><a href="#contactus" className='uppercase accent-text clickable'>Get in touch</a></li>
                </ul>
            </div>

           
            <div className='menu-responsive'>
                {!responsiveMenuBarVisible &&
                    <div className='hamburger-icon-wrap clickable' onClick={() => handleOpenResponsiveMenu()}>
                        <Menu />
                    </div>
                }
            </div>
        </header>
    );

}