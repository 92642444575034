import React from 'react';


export const ColoredChip = ({text, color='white'}) => {

    return(
        <div className='colored-chip' style={{backgroundColor: `${color}`}}>
            <span className='chip-text uppercase'>{text}</span>
        </div>
    )
}